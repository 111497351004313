<template>
  <v-row>
    <v-col>
      <!-- {{manualBonusRes}}
    {{bonusResList}} -->
      <v-row>
        <v-col>
          <BaseDialogFieldBlock
            title="Бонусная операция"
            description=""
          >
            <v-select
              v-model="model.action_json.bonus_res_id"
              class=""
              :items="bonusResList"
              item-text="title"
              item-value="id"
              placeholder="Выберите бонусную операцию"
              outlined
              :rules="[
                v => !!v || 'Выберите бонусную операцию',
              ]"
              :loading="GetListAction || loading"
            />
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <BaseDialogFieldBlock
            title="Клиент операции"
            description="Укажите клиента, по бонусам которого будет проведена операция."
          >
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-radio-group
                      v-model="model.action_json.account_type"
                      class="mt-0"
                      hide-details
                    >
                      <v-radio
                        label="Текущий клиент (для которого отработала активность) "
                        :value="'current'"
                      />
                      <v-radio
                        label="Реферер"
                        :value="'referrer'"
                      />
                      <v-radio
                        label="Другой клиент (выбрать из списка)"
                        :value="'manual'"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="model.action_json.account_type === 'referrer'">
                  <v-col>
                      <v-select v-model="model.action_json.account_referrer_level" :items="referrerLevels" 
                      label="Уровень реферера"
                      :rules="[
                         v=> !!v || 'Выберите уровень реферера'
                       ]"
                      >
                      </v-select>
                  </v-col>
                </v-row>
                <v-row v-else-if="model.action_json.account_type === 'manual'">
                  <v-col>
                    <account-select
                      v-model="model.action_json.account_id"
                      placeholder="Выберите клиента"
                      :rules="[
                        v=> !!v || 'Выберите клиента'
                      ]"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <v-row v-if="model.action_type != ACTION_ENUM.SALE_BONUS_CREDIT.id && !!selectedBonusRes">
        <v-col>
          <BaseDialogFieldBlock
            title="Сумма"
            description=""
          >
            <v-row v-if="bonusValueTypeList.length > 1">
              <v-col>
                <v-radio-group
                  v-model="model.action_json.bunus_value_type"
                  class="mt-0"
                  hide-details
                >
                  <v-radio 
                    v-for="item in bonusValueTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"                   
                  />                 
                </v-radio-group>
              </v-col>
            </v-row>         
            <v-row v-if="model.action_json.bunus_value_type==='MANUAL'">
              <v-col>
                <base-bonus-operation-field
                  v-model="model.action_json.value"
                  :decimal="isFloatBonusUnit"
                  placeholder="Введите сумму бонусов"
                  hide-details
                />
              </v-col>
            </v-row>

            <!-- <base-text-field
              v-model.number="model.action_json.value"
              :rules="valueRules"
              :validation-placement="'top'"
              placeholder="Сумма"
              class="percent-field"
              validate-on-blur
            /> -->
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
      <v-row v-if="model.action_type == ACTION_ENUM.SALE_BONUS_CREDIT.id">
        <v-col>
          <BaseDialogFieldBlock
            title="Тип расчета бонусов"
            description=""
          >
            <v-radio-group
              v-model="model.action_json.sale_price_type"
              class="mt-0"
              hide-details
            >
              <v-radio
                label="Расчет от суммы оплаты"
                :value="'discounted'"
              />
              <v-radio
                label="Расчет от общей суммы продажи"
                :value="'original'"
              />
            </v-radio-group>
          </BaseDialogFieldBlock>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { isFilled, isNumeric, isPosNumber } from '@/utils/validate'
  import { RESOURCE_TYPE_ENUM } from '@/models/enums'
  import Vue from 'vue'

  import ProgramEventBroadcasterHandler from '@/models/program/broadcasterHandler'

  export default {
    components: {
      AccountSelect: () => import('@/components/ModelSelect/AccountSelect'),
    },
    model: {
      prop: 'model',
      event: 'update',
    },
    constants: {
      ACTION_ENUM: ProgramEventBroadcasterHandler.ACTION_ENUM,
    },
    props: {
      model: {
        type: Object,
        required: true,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        GetListAction: false,
        valueRules: [
          (v) => isFilled(v) || 'Введите сумму',
          (v) => isNumeric(v) || 'Должно быть числом',
          (v) => isPosNumber(v) || 'Должно быть положительным',
          (v) => v <= this.$config.MAX_TRAN_VALUE || `Не более ${this.$config.MAX_TRAN_VALUE}`,
        ],
        referrerLevels: Array.from({length: 10}, (item, i) => new Object({value: i + 1, text: (i+1) + ' уровень'}) ), 
      }
    },
    computed: {
      ...mapGetters('company/bonus_resources', ['manualBonusRes', 'buyBonusRes']),
      bonusResList () {
        if (this.model.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.SALE_BONUS_CREDIT.id) {
          return this.buyBonusRes.filter(item => item.resource_type_enum === RESOURCE_TYPE_ENUM.SOURCE)
        } else {
          return this.manualBonusRes.filter(item => {
            if (this.model.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_CREDIT.id) {
              return item.resource_type_enum === RESOURCE_TYPE_ENUM.SOURCE
            } else if (this.model.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_DEBIT.id) {
              return item.resource_type_enum === RESOURCE_TYPE_ENUM.TARGET
            }
          })
        }
      },
      selectedBonusRes () {
        return this.$_.findWhere(this.bonusResList, { id: this.model.action_json.bonus_res_id })
      },
      isFloatBonusUnit () {
        return this.selectedBonusRes?.bonus_score.unit_type.type_enum === 'FLOAT'
      },
      isCreditBonusRes () {
        return this.selectedBonusRes?.resource_type_enum === RESOURCE_TYPE_ENUM.SOURCE
      },
      isCreditAction(){
        return this.model.action_type === ProgramEventBroadcasterHandler.ACTION_ENUM.BONUS_CREDIT.id
      },
      isLimitedBonusUnit () {
        return this.selectedBonusRes?.bonus_score.unit_type.max_value != null
      },
      bonusValueTypeList(){
        return [
          { value: 'TO_MAX', label: 'Начислить до максимума', hide: !this.isCreditAction || !this.isLimitedBonusUnit  },
          { value: 'TO_MIN', label: 'Списать до нуля', hide: this.isCreditAction},
          { value: 'MANUAL', label: 'Другая сумма'},
        ].filter( (x) => !x.hide )
      },
      targetAccountType: {
        get: function () {
          return this.model.action_json.account_type || 'current'
        },
        set: function (v) {
          this.model.action_json.account_type = v
        },
      },
      // isCurrentAccount: {
      //   get: function () {
      //     return this.model.action_json.account_id === undefined
      //   },
      //   set: function (v) {
      //     this.model.action_json.account_id = v ? undefined : null
      //     console.log('this.model.action_json', this.model.action_json)
      //   },
      // },
    },
    watch: {
      'model.action_json.bonus_res_id'(v){        
        Vue.set(this.model.action_json, 'bunus_value_type', 'MANUAL')
      }
    },
    mounted () {
      this.loadBonusRes()
      console.log('bonusActions mounted')
      Vue.set(this.model.action_json, 'sale_price_type', this.model.action_json.sale_price_type || 'discounted')
      Vue.set(this.model.action_json, 'account_type', this.model.action_json.account_type || 'current')
      Vue.set(this.model.action_json, 'bunus_value_type', this.model.action_json.bunus_value_type || 'MANUAL')
      Vue.set(this.model.action_json, 'account_referrer_level', this.model.action_json.account_referrer_level || 1)
      if (!this.model.action_json.account_id) Vue.set(this.model.action_json, 'account_id', undefined)
    },
    methods: {

      ...mapActions({
        GetList: 'company/bonus_resources/GetList',
      }),

      async loadBonusRes () {
        try {
          this.GetListAction = true
          await this.GetList(this.model.program_id)
        } catch (e) {
          console.error(e)
        } finally {
          this.GetListAction = false
        }
      },

    },
  }
</script>

<style lang="scss" scoped>

</style>
